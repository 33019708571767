// Mood Board Color
export const BG_PRIMARY = '#F6F3EE'; // Warna Chreme Muda
export const BG_SECONDARY = '#B4C5D5'; // Warna Chreme Yang Lebih Tua
export const BG_ALTERNATIVE = '#B4C5D5'; // Warna Ketiga Coklat Tua
// Text Color
export const TEXT_PRIMARY = '#100F0D'; // Warna Hitam Body
export const TEXT_SECONDARY = '#B4C5D5'; // Warana Coklat Tua sama dengan BG_ALTERNATIVE
export const TEXT_ALTERNATIVE = '#100F0D'; // Alternative Color
// Music & Navigation Color
export const NAVIGATION_COLOR = '#748ea7'; // Warna BG
export const ICON_NAVIGATION_COLOR = 'white'; // Warna Iconnya
// Drawer Color
export const DRAWER_COLOR = 'rgb(116 84 63)';
export const BORDER_COLOR = '#EDE0D1';
export const FOOTER_COLOR = '#7e6c696e';
// Default Button Color
export const BUTTON_PROPS = {
  _active: { borderColor: 'transparent' },
  _hover: { borderColor: 'transparent' },
  _focus: { borderColor: 'transparent' },
};